import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { Anwendungssuche } from '../../generated/openapi/search/anwendungen'
import { TextService } from '../services/text.service'
import { AnwendungssucheService } from '../services/anwendungssuche.service'
import { ecsTargets, searchMandanten, tUser } from '../types'
import { INews } from '../interfaces/inews'
import { Translation } from '../../generated/openapi/textservice'
import { AuthService } from '../services/auth.service'
import { EnvironmentService } from '../services/environment.service'
import { SlicePipe } from '@angular/common'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { SafeHtmlPipe } from '../pipes/safe-html.pipe'
import { RouterLink } from '@angular/router'
import { AppitemComponent } from '../uicomponents/appitem/appitem.component'
import { ToastComponent } from '../uicomponents/toast/toast.component'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { DashboardHeaderComponent } from './header/header.component'
import { DashboardFooterComponent } from './footer/footer.component'
import { NewsitemComponent } from '../uicomponents/newsitem/newsitem.component'
import { UnitOverviewComponent } from '../uicomponents/unit-overview/unit-overview.component'
import { InsightsService } from '../services/insights.service'
import { News, SucheRestService as SucheApi, Tag } from '../../generated/openapi/search/search'
import { KeycloakService } from 'keycloak-angular'
import { HttpHeaders } from '@angular/common/http'

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [
    SlicePipe,
    SafeHtmlPipe,
    DesignSystemModule,
    RouterLink,
    AppitemComponent,
    ToastComponent,
    NgxGoogleAnalyticsModule,
    DashboardHeaderComponent,
    DashboardFooterComponent,
    NewsitemComponent,
    UnitOverviewComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export default class DashboardComponent {

  news: Array<INews> = []
  apps: Array<Anwendungssuche> = []
  textsObjects: Array<Translation> = []
  texts: Array<string | undefined> = []
  showFavToast = false
  user: tUser = {
    userId: '',
    firstName: '',
    lastName: '',
    isInnendienst: false,
    isAssistent: false,
    isVertreter: false,
    isEditor: false
  }

  constructor(
    public textService: TextService,
    private anwendungssucheService: AnwendungssucheService,
    private sucheApi: SucheApi,
    private auth: AuthService,
    private envService: EnvironmentService,
    private insightsService: InsightsService,
    private keycloak: KeycloakService
  ) {
    this.loadUser().then(() => {
      this.loadEnv().then(() => {
        this.fetchNews()
        const mandant = this.user.isInnendienst ? searchMandanten['meineDVAG-ID-Keycloak'] : searchMandanten['meineDVAG-Keycloak']
        this.anwendungssucheService.fetchApps(mandant).subscribe(() => {
          this.apps = this.anwendungssucheService.getRecommendedApps(mandant)
        })
        this.textsObjects = this.textService.getTextsObjectsForIds(1, 41, 42, 43, 44, 45, 65, 67, 122, 88, 155, 161, 160, 128, 129, 162, 163)
      })
    })
  }

  showToast(show: boolean) {
    this.showFavToast = show
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.sucheApi.configuration.basePath = config.apis.meineDvagSuche
  }

  async loadUser() {
    this.user = await this.auth.getUser()
  }

  async fetchNews() {
    this.sucheApi.defaultHeaders = new HttpHeaders({'Authorization': 'Bearer ' + await this.keycloak.getToken()})

    for (const target in ecsTargets) {
      const _target = ecsTargets[target as keyof typeof ecsTargets]
      if (_target === 'intranet') {
        continue
      }
      this.sucheApi.news({target: _target})
        .subscribe({
          next: (data) => {
            if (Object.keys(data).length) {
              try {
                const _news = this.formatNews((data[_target]!), _target)
                this.news = _news.concat(this.news)
              } catch (e) {
                this.insightsService.logException(e as Error)
              }
            }
          },
          error: (error) => this.insightsService.logException(error)
        })
    }
  }

  formatNews(data: News[], target: ecsTargets): INews[] {
    let list: Array<INews> = []
    const owner = target

    data.forEach((newsItem) => {
      newsItem.Tags = newsItem.Tags.filter(tag => tag && tag.Title && tag?.Title?.indexOf('order-') > -1)

      if (newsItem.Tags.length) {
        const INewsItem = newsItem as INews
        INewsItem.targetName = owner
        INewsItem.ImageUrl = 'data:image/png;base64,' + (INewsItem.ImageBase64 ? `${INewsItem.ImageBase64}` : '')

        switch (owner) {
          case 'welten':
            const _parts = INewsItem.Url.split('/')
            switch (_parts[3]) {
              case 'it-welt':
                INewsItem.owner = this.textService.get(44).toString()
                break
              case 'beratungswelt':
                INewsItem.owner = this.textService.get(43).toString()
                break
              case 'vbwelt':
              default:
                INewsItem.owner = this.textService.get(42).toString()
                break
            }
            INewsItem.targetName = 'welten'
            break
          case 'intranet':
            INewsItem.owner = 'Intranet'
            INewsItem.targetName = 'idintranet'
            break
          default:
            INewsItem.owner = owner
            break
        }
        INewsItem.PublicationDate = this.myFormatDate(new Date(INewsItem.PublicationDate!))
        INewsItem.order = 0
        INewsItem.Tags.forEach((tag: Tag) => {
          INewsItem.order = Number(tag?.Title?.substring(tag.Title.length - 1, tag.Title.length))
        })

        list.push(INewsItem)
      }
    })

    list = list.sort((a: INews, b: INews) => a.order - b.order)
    return list
  }

  userHasUnitOverview(): boolean {
    return !!(!this.user.isInnendienst && !this.user.isVertreter && !this.user.isAssistent && this.user.strukturstufe?.strukturstufeNumber && +this.user.strukturstufe.strukturstufeNumber > 160)
  }

  myFormatDate(date: Date): string {
    let d = new Date(date)
    let month = (d.getMonth() + 1).toString().padStart(2, '0')
    let day = d.getDate().toString().padStart(2, '0')
    let year = d.getFullYear()
    return [day, month, year].join('.')
  }
}
