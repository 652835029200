import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { AuthService } from '../services/auth.service'
import { tUser } from '../types'

@Component({
  standalone: true,
  selector: 'app-userlane',
  template: ``
})
export class UserlaneComponent implements AfterViewInit {
  user!: tUser

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private auth: AuthService
  ) {
    // none
  }

  ngAfterViewInit() {
    const elem = this.document.createElement('script')
    elem.type = 'text/javascript'
    elem.src = '//cdn.userlane.com/userlane.js'
    elem.id = 'Userlane'
    const __this = this
    elem.onload = function () {
      __this.afterScriptAdded()
    }
    this.elementRef.nativeElement.appendChild(elem)
  }

  async loadUser() {
    this.user = await this.auth.getUser()
  }

  private afterScriptAdded() {
    const userlane = (window as { [key: string]: any })['Userlane']
    this.loadUser().then(() => {
      if (userlane) {
        userlane('identify', this.user.userId, {
          vorname: this.user.firstName,
          name: this.user.lastName,
          karrierestufe: this.user.strukturstufe
        })

        // initialize Userlane
        userlane('init', 'l9lkj')
      }
    })
  }
}
