import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core'
import {TextService} from "../../services/text.service"
import {Translation} from "../../../generated/openapi/textservice"
import {AuthService} from "../../services/auth.service"
import {Router} from "@angular/router"
import {GoogleAnalyticsService, NgxGoogleAnalyticsModule} from 'ngx-google-analytics'
import {EnvironmentService} from "../../services/environment.service"
import {DesignSystemModule} from '@dvag/design-system-angular'
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe'
import { BrowsersizeService } from '@dvag/uinfo-ui-lib'


@Component({
  standalone: true,
  selector: 'app-dashboard-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    DesignSystemModule,
    SafeHtmlPipe,
    NgxGoogleAnalyticsModule
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DashboardFooterComponent implements OnInit {

  texts: Array<string> = []
  textObjects: Array<Translation> = []
  isInnendienst = false
  isKonzernMa = false
  serviceContactsUrl: URL | string = ''
  dldbsSearchUrl: URL | string = ''

  ngOnInit() {
    this.texts = this.textService.getTextsForIds([], 2,3,5,6,7,9,12,13,14,15,50,66,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,87,117,119,120,160,161,155,130,131,132,8,10,165,166)
    this.textObjects = this.textService.getTextsObjectsForIds(8,10)
  }

  constructor(public textService: TextService, private auth: AuthService, private browserSizesService: BrowsersizeService, public router: Router, public gaService: GoogleAnalyticsService, private envService: EnvironmentService) {
    this.loadUser().then()
    this.loadEnv().then()
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.serviceContactsUrl = config.apis.serviceContacts
    this.dldbsSearchUrl = config.apis.dldbsSearch
  }

  async loadUser() {
    let user = await this.auth.getUser()
    this.isInnendienst = user.isInnendienst
    this.isKonzernMa = user.userTyp === 'KONZERN_MITARBEITER'
  }
}
