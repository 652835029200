import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http'
import { from, Observable, switchMap } from 'rxjs'
import { inject } from '@angular/core'
import { AuthService } from '../services/auth.service'

export const authIntercept: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  if (isTokenNeededForRequest(req)) {
    return from(inject(AuthService).getToken()).pipe(
      switchMap(token => {
        const authReq = req.clone({setHeaders: {Authorization: `Bearer ${token}`}})
        return next(authReq)
      })
    )
  }

  return next(req)
}

const excludedUrls: string[] = ['version/version.json', 'assets/config/config.json']

const isTokenNeededForRequest = (req: HttpRequest<unknown>) => {
  for (const url of excludedUrls) {
    if (req.url.includes(url)) {
      return false
    }
  }
  return true
}
