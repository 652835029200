import { Pipe, PipeTransform } from '@angular/core'
import {DomSanitizer} from "@angular/platform-browser"

@Pipe({
  name: 'safeHtml',
  standalone: true
})

export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {
    // nothing
  }

  transform(value: any) {
    return this.sanitized.bypassSecurityTrustHtml(value?.toString()) // NOSONAR not used in secure contexts
  }
}
