<app-dashboard-header></app-dashboard-header>
<dx-container type="page" color="background" class="container-padding news-container">
  <dx-grid mq1="12/*" mq4="12/4-4-4/*" mq5="12/*">
    <dx-slide-show amount="" spacebetween="25" style="width: 100%">
      <dx-media-query-value property="amount" mq1="1" mq2="1" mq3="2" mq4="3" mq5="3"></dx-media-query-value>
      @for (newsItem of news | slice : 0 : 3; track newsItem.ImageUrl; let i = $index) {
        <app-newsitem [img]="newsItem.ImageUrl"
          [publicationDate]="newsItem.PublicationDate"
          [tags]="newsItem.Tags" [owner]="newsItem.owner" [link]="newsItem.Url" [title]="newsItem.Title"
          [text]="newsItem.Summary" [targetName]="newsItem.targetName"
          gaCategoryValue="{{textsObjects[161]}}:{{textsObjects[155]}}>{{textsObjects[67]}}>{{textsObjects[88]}}>{{i + 1}}>uid-000{{i + 1}}">
        </app-newsitem>
      }
    </dx-slide-show>
  </dx-grid>
</dx-container>
@if (userHasUnitOverview()) {
  <dx-container type="page" color="background" class="container-padding">
    <app-unit-overview></app-unit-overview>
  </dx-container>
}
<ng-container>
  <dx-container type="page" color="background" class="container-padding">
    <dx-grid mq1="12/*">
      <dx-text type="h4" class="mt-0 container-headline"
      [innerHTML]="textsObjects[1] | safeHtml">{{textsObjects[1]}}</dx-text>
      <dx-slide-show amount="" spacebetween="25" style="width: 100%">
        <dx-media-query-value property="amount" mq1="1" mq2="1" mq3="2" mq4="3" mq5="4"></dx-media-query-value>
        @for (appItem of apps.slice(0,4); track appItem.id; let i = $index) {
          @if (appItem.isVisible) {
            <app-appitem
              type="small"
              [id]="appItem.id"
              [title]="appItem.title"
              [text]="appItem.text"
              [deviceIds]="appItem.devices"
              [category]="appItem.category"
              [isVisible]="appItem.isVisible"
              [showDevices]="false"
              [overrideVisibility]="false"
              [applicationURL]="appItem.applicationURL"
              [isFav]="appItem.isFav"
              [infoURL]="appItem.infoURL"
              (showToastEvent)="showToast($event)"
              gaCategoryValue="{{textsObjects[161]}}:{{textsObjects[155]}}>{{textsObjects[1]}}>{{textsObjects[45]}}>{{i + 1}}>uid-000{{i + 4}}"
              [iconName]="appItem.iconName"
              gaLocation="{{textsObjects[155]}}">
            </app-appitem>
          }
        }
      </dx-slide-show>
      <app-toast [(showToast)]="showFavToast"></app-toast>
    </dx-grid>
    <div [class]="'container-padding pb-0 button-allapps'">
      <a [routerLink]="['/apps']" gaEvent="click" gaCategory="{{textsObjects[161]}}:{{textsObjects[155]}}>{{textsObjects[65]}}>uid-0014"
        gaLabel="{{textsObjects[65]}}">
        <dx-button id="all-applications-bottom" label="{{textsObjects[65]}}" type="secondary-m"
        icon="pfeil-rechts" iconposition="right"></dx-button>
      </a>
    </div>

  </dx-container>
</ng-container>
<dx-container type="page" color="background" class="container-padding">
  <app-dashboard-footer></app-dashboard-footer>
</dx-container>
